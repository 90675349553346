body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* height: 100vh; */
  width: 100vw;
  background-color: #191919;
  color: #FFEFB6;
  font-family: monospace, monospace;
}

input{
  background-color: #FFEFB6;
  color: #191919;
  font-family: monospace, monospace;
}

input:focus{
  outline: #FFEFB6 dotted 1px;
}

.button{
  background-color: #FFEFB6;
  color: #191919;
  font-family: monospace, monospace;
  cursor: pointer;
}

ul>li{
  /* text-decoration: none; */
  list-style: none;
}

ul>li>a{
  text-decoration: none;
  color: #FFEFB6;
  margin-right: 15px;
  transition: all 0.3s ease-in-out;
  padding: 5px;
}

ul>li>a:hover, a.selected{
  color: #191919;
  background-color: #FFEFB6;
}

nav{
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #191919;
  border-bottom: 1px dashed #FFEFB6;
}

.card{
  transition: all 0.3s ease-in-out;
  flex-direction: column;
  width: 220px;
  min-height: 220px;
  justify-content: start;
  margin-bottom: 10px;
}

.card:hover{
  transform: scale(1.1);
  color: #191919;
  background-color: #FFEFB6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
